<template>
  <div @click="toEvent(event)" class="event-card">
    <div class="event-pic label">
      <img :src="event.image || require('@/assets/dev/event-pic.jpg')" />
    </div>
    <div class="event-title">{{ event.title }}</div>
    <div class="event-desc">
      {{ event.subTitle }}
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'EventCard',
  components: {},
  props: {
    event: {
      type: Object,
      default() {
        return {
          image: '',
          title: '',
          subTitle: '',
          activityId: '',
        }
      },
    },
  },
  setup() {
    function toEvent(event) {
      // eslint-disable-next-line
      gtag('event', 'click', {
        event_id: '001',
        event_name: '主題精選',
        location: '集團介紹, 飯店介紹, 餐廳介紹',
        event_category: `主題精選--${event.title}`,
        event_label: event.title,
        event_value: 1,
      })
      router.push({ name: 'Event', params: { eid: event.activityId } })
    }
    return { toEvent }
  },
}
</script>

<style scoped lang="scss">
.event-pic {
  img {
    @apply w-full;
  }
}
.event-title {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.32px;
  color: #a66621;
  @apply font-medium pt-4 pb-2;
}
.event-desc {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #545454;
  @apply font-light;
  overflow: hidden;
  word-break: break-all;
  height: 48px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
