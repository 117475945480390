<template>
  <div v-show="open" @click="$emit('close')" id="modal" class="bottom-modal">
    <div @click.stop class="content">
      <div class="content-head">
        <button @click="$emit('close')" class="close">
          <img src="@/assets/img/icon/close_black.svg" /></button
        >{{ title }}
      </div>
      <div class="content-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
const bodyScrollLock = require('body-scroll-lock')
const disableBodyScroll = bodyScrollLock.disableBodyScroll
const enableBodyScroll = bodyScrollLock.enableBodyScroll

export default {
  name: 'Bottom-modal',
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '標題',
    },
  },
  watch: {
    open(val) {
      if (val) {
        const targetElement = document.querySelector('#modal')
        disableBodyScroll(targetElement)
      } else {
        const targetElement = document.querySelector('#modal')
        enableBodyScroll(targetElement)
      }
    },
  },
  methods: {
    handleMove(e) {
      e.stopPropagation()
    },
  },
  beforeUnmount() {
    const targetElement = document.querySelector('#modal')
    enableBodyScroll(targetElement)
  },
}
</script>

<style scoped lang="scss">
.bottom-modal {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.content {
  // max-height: 75%;
  // overflow-y: scroll;
  background: #fefdfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  bottom: 0;
  position: fixed;
  width: 100%;
  padding: 24px 5.5%;
  &::before {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    display: block;
    background: #e5e5e5;
    border-radius: 100px;
    height: 5px;
    width: 9%;
  }
  .content-head {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #222222;
    position: relative;
    .close {
      position: absolute;
      left: 0;
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .content-body {
    max-height: 70vh;
    overflow-y: scroll;
  }
}
</style>
