<template>
  <div @click="openMenu" class="menu">
    <div class="name">{{ menu.title }}</div>
    <div class="desc">
      <div class="text">{{ menu.profile }}</div>
      <div class="image">
        <img :src="menu.image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  components: {},
  props: {
    menu: {
      type: Object,
      default() {
        return {
          title: '',
          profile: '',
          image: '',
          menuUrl: '',
        }
      },
    },
  },
  methods: {
    openMenu() {
      window.openUrlExternal(this.menu.menuUrl)
    },
  },
}
</script>

<style scoped lang="scss">
.menu {
  .name {
    padding: 24px 0 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: -0.32px;
    color: #ceb17d;
  }
  .desc {
    display: flex;
    justify-content: space-between;
    .text {
      width: 76.1%;
      padding-right: 16px;
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: -0.32px;
      color: #545454;
    }
    .image {
      flex: 0 0 23.9%;
      min-height: 80px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
