<template>
  <div class="deco-title">
    <div class="deco">
      <div class="line"></div>
      <div class="icon"></div>
    </div>
    <h3>{{ title }}</h3>
    <div class="deco right">
      <div class="line"></div>
      <div class="icon"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecoTitle',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.deco-title {
  @apply flex;
  align-items: center;
  padding: 19px 0 14px 0;
  h3 {
    padding: 0 27px;
  }
  .deco {
    flex-grow: 1;
    @apply flex;
    align-items: center;
    .icon {
      width: 12.89px;
      height: 13.5px;
      mask: url('../assets/img/icon/title-deco-left.svg');
      background: linear-gradient(
        270deg,
        #ac965f 0%,
        rgba(172, 150, 95, 0) 100%
      );
    }
    &.right {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  .line {
    height: 1px;
    flex-grow: 1;
    background: linear-gradient(270deg, #ac965f 0%, rgba(172, 150, 95, 0) 100%);
  }
}
</style>
