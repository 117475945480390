<template>
  <div class="event-swiper">
    <div class="swiper">
      <swiper
        :slides-per-view="1.073"
        :space-between="15"
        :pagination="{ clickable: true }"
      >
        <swiper-slide
          v-for="(event, index) in slicedEventList"
          :key="'event' + index"
        >
          <div v-if="index == 6" class="more">
            <div @click="toEventList" class="more-content">
              <div class="icon">
                <img src="@/assets/img/icon/event-more.svg" />
              </div>
              <div class="text">尚有更多精選方案</div>
              <div class="link">前往查看</div>
            </div>
          </div>
          <EventCard v-else :event="event" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import EventCard from '@/components/eventCard.vue'

SwiperCore.use([Pagination])

export default {
  name: 'EventSwiper',
  components: { Swiper, SwiperSlide, EventCard },
  props: {
    eventList: {
      type: Array,
      default() {
        return []
      },
    },
    tab: {
      type: String,
      default: '0',
    },
    chip: {
      type: String,
      default: '0',
    },
  },
  computed: {
    slicedEventList() {
      return this.eventList.slice(0, 7)
    },
  },
  methods: {
    toEventList() {
      this.$router.push({
        name: 'EventList',
        query: {
          chip: this.chip,
          tab: this.tab,
        },
      })
    },
  },
  setup() {
    const state = reactive({
      swiperOption: {
        slidesPerView: 2.37,
        initialSlide: 0,
        spaceBetween: 8,
        loop: false,
      },
    })
    return {
      state,
    }
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  background-color: #fefdfb;
  box-shadow: 0px 4px 16px #ede9db;
  padding: 0 5.33% 51px;
  ::v-deep(.swiper-pagination) {
    bottom: 15px;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: 1;
      margin: 0 4px;
      background-color: #c4c4c4;
      &-active {
        background-color: #9d8147;
      }
    }
  }
}
.more {
  width: 100%;
  height: 276px;
  background: #fefdfb;
  border: 1px solid rgba(206, 177, 125, 0.3);
  display: flex;
  .more-content {
    margin: auto;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    .icon img {
      margin: auto;
    }
    .text {
      padding-top: 24px;
      color: #383838;
      font-size: 15px;
    }
    .link {
      padding-top: 4px;
      color: #ceb17d;
      font-size: 16px;
    }
  }
}
</style>
